<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container">
    <div class="system-pages-title d-flex ">
      <div class="me-2 trusted-icon">
        <TrustedAgenciesIcon/>
      </div>
      {{ $t('trusted-agencies') }}
    </div>
    <div class="system-pages-content" v-if="$root.$i18n.locale === 'bg'">
      <p><strong>Търсете синия маркер</strong> - в нашата платформа може да намерите само реални обяви от предварително
        одобрени и надеждни агенции за недвижими имоти.</p>
      <p><br></p>
      <p>Разчитаме на коректността и сме ограничили свободното регистриране на агенции за имоти - <strong>единствено физическите
        лица могат да се регистрират свободно</strong>. Това означава, че в нашата платформа може да намерите само два вида обяви
        - от физически лица (без маркер) или коректни агенции (със син маркер). Имаме механизми за предотвратяването и
        минимизирането на фалшиви обяви, което приемаме като наша мисия.</p>
      <p><br></p>
      <p>На първо място държим на качеството - всяка агенция за недвижими имоти и всеки партньор на Съквартирантите е
        предварително проверен и задължен да спазва добрите практики с дългосрочен договор за партньорство. При системни
        нарушения, ние снемаме доверието си и прекратяваме работа с дадения брокер или партньор.</p>
    </div>
    <div class="system-pages-content" v-else>
      <p><strong>Discover Authentic Listings with Confidence</strong>&nbsp;<br /><br />Look for the blue marker on our platform to find listings exclusively from pre-approved and reliable real estate agencies.&nbsp;<br /><br />We prioritize accuracy and trustworthiness,&nbsp;<strong>allowing only individuals to register freely.</strong>&nbsp;This means you'll encounter two types of ads: those from individuals (without a tag) and those from legitimate agencies (with a blue tag). Our robust mechanisms are designed to prevent and minimize fake ads, ensuring a secure experience for you.&nbsp;<br /><br />Quality is our top priority. Every real estate agency and partner of TheRoommates undergoes thorough screening and commits to long-term partnership agreements, adhering to best practices. In cases of systemic violations, we promptly revoke access to maintain our high standards.</p>
    </div>
  </div>


</template>
